


























































































































































































































































import { Api, CountryCode, dateFromISO8601Date, ISO8601Date, RetentionEventType, RetentionGraphResponse, SchoolOrgType, StripeInvoiceAccountRegion, SubscriptionProductType } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS } from 'chart.js'
import { UnreachableCaseError } from 'ts-essentials'
import BarGraph from './graphs/BarGraph.vue'
import RetentionEventsList from './RetentionEventsList.vue'

ChartJS.register(ChartDataLabels)

interface DataSeries {
  accountRegion: StripeInvoiceAccountRegion
  products: SubscriptionProductType[]
  orgTypes: SchoolOrgType[]
  countries: CountryCode[]
}

@Component({
  name: 'RetentionRatesGraph',
  components: { BarGraph, RetentionEventsList }
})
export default class RetentionRatesGraph extends Mixins(ComponentHelper) {
  StripeInvoiceAccountRegion = StripeInvoiceAccountRegion
  SchoolOrgType = SchoolOrgType
  CountryCode = CountryCode
  SubscriptionProductType = SubscriptionProductType

  options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return this.getTooltipText(context)
          },
          title (tooltipItems: any[]) {
            return tooltipItems[0].dataset.label
          }
        }
      }
    },
    scales: {
      A: {
        type: 'linear',
        display: true,
        position: 'left'
      },
      B: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        min: 0,
        max: 1,
        ticks: {
          max: 1,
          min: 0,
          beginAtZero: true,
          callback: (value, _index, _values) => {
            return Intl.NumberFormat('en', { style: 'percent' }).format(value)
          }
        }
      },
      x: { id: 'C', stacked: true }
    },
    onClick: this.graphBarClicked

  }

  moment = moment

  period: 'day' | 'week' | 'month' | 'year' = 'month'

  start: Date = moment().utc().startOf('month').subtract(12, 'months').toDate()

  end: Date = moment().utc().endOf('day').toDate()

  activeSeries: DataSeries[] = [{
    accountRegion: 'GB',
    countries: [],
    orgTypes: [],
    products: []
  }]

  seriesMap: Map<string, RetentionGraphResponse> = new Map()

  seriesTrigger = 0 // for forced reactivity of map

  isAddingNewSeries = false

  orgTypes: SchoolOrgType[] = []

  products: SubscriptionProductType[] = []

  countries: CountryCode[] = []

  retentionsByValue: boolean = false

  contextMenuData: RetentionGraphResponse['data'][number] | null = null

  contextMenuSeries: DataSeries | null = null

  breakdownHistory: { period: 'day' | 'week' | 'month' | 'year', start: Date, end: Date }[] = []

  accountRegion: StripeInvoiceAccountRegion = 'GB'

  retentionEventsList: {
    account_region: StripeInvoiceAccountRegion
    countries?: CountryCode[]
    organisation_types?: SchoolOrgType[]
    products?: SubscriptionProductType[]
    event: RetentionEventType
    start: Date
    end: Date
  } | null = null

  accountRegionChanged (reg: StripeInvoiceAccountRegion) {
    this.activeSeries = [
      {
        accountRegion: reg,
        countries: [],
        orgTypes: [],
        products: []
      }
    ]

    this.getSeriesData()
  }

  onPeriodChanged () {
    const fiveYearsAgo = moment().utc().startOf('year').subtract(5, 'years')
    const earliestData = moment('2019-01-01').utc().startOf('year')

    switch (this.period) {
      case 'day':
        this.start = moment().utc().startOf('day').subtract(30, 'days').toDate()
        this.end = new Date()
        break
      case 'week':
        this.start = moment().utc().startOf('week').subtract(13, 'weeks').toDate()
        this.end = moment().utc().endOf('week').toDate()
        break
      case 'month':
        this.start = moment().utc().startOf('month').subtract(12, 'months').toDate()
        this.end = new Date()
        break
      case 'year':
        this.start = fiveYearsAgo.isBefore(earliestData) ? earliestData.toDate() : fiveYearsAgo.toDate()
        this.end = moment().utc().endOf('year').toDate()
        break
    }

    this.breakdownHistory = []

    this.getSeriesData()
  }

  getTooltipText (context) {
    if (context.datasetIndex % 3 === 2) {
      return `${this.retentionsByValue ? this.formatCurrency(context.parsed.y, this.currency) : context.parsed.y} (${Intl.NumberFormat('en', { style: 'percent' }).format(1 - context.chart.config.data.datasets[context.datasetIndex - (context.datasetIndex % 3)].data[context.dataIndex])})` // lost
    } else if (context.datasetIndex % 3 === 1) {
      return `${this.retentionsByValue ? this.formatCurrency(context.parsed.y, this.currency) : context.parsed.y} (${Intl.NumberFormat('en', { style: 'percent' }).format(context.chart.config.data.datasets[context.datasetIndex - (context.datasetIndex % 3)].data[context.dataIndex])})` // retained
    } else if (context.datasetIndex % 3 === 0) {
      return `${Intl.NumberFormat('en', { style: 'percent' }).format(context.parsed.y)}` // percent
    }
  }

  getCountryName (country: CountryCode) {
    switch (country) {
      case 'GB-ENG':
        return 'England'
      case 'GB-NIR':
        return 'Northern Ireland'
      case 'GB-SCT':
        return 'Scotland'
      case 'GB-WLS':
        return 'Wales'
      case 'GB-CHA':
        return 'Channel Islands'
      default:
        return (new (Intl as any).DisplayNames(['en'], { type: 'region' })).of(country)
    }
  }

  datesChanged () {
    this.breakdownHistory = []
    // correct for DST since datepicker insists on making that adjustment
    this.start = moment(this.start).add(moment(this.start).utcOffset(), 'minutes').utc().toDate()
    this.end = moment(this.end).add(moment(this.end).utcOffset(), 'minutes').utc().toDate()

    this.getSeriesData()
  }

  async mounted () {
    document.addEventListener('click', (e) => {
      const menu = this.$refs.contextMenu as HTMLElement

      if (menu && !menu.contains(e.target as HTMLElement)) {
        this.closeContextMenu()
      }
    })

    await this.getSeriesData()
  }

  graphBarClicked (event, elements) {
    if (elements.length > 0) {
      const barIndex = elements[0].index

      if (elements[0].datasetIndex % 3 === 0) {
        return // retention line clicked
      }
      const dataIndex = Math.floor(elements[0].datasetIndex / 3)
      const series = this.activeSeries[dataIndex]

      const seriesKey = this.formSeriesKey(series, this.period, this.start, this.end)
      const seriesData = this.seriesMap.get(seriesKey)

      if (seriesData) {
        this.openContextMenu(seriesData.data[barIndex], series, event.native.layerX, event.native.layerY)
      }
    }
  }

  closeContextMenu () {
    const menu = this.$refs.contextMenu as HTMLElement

    menu.style.display = 'none'
    this.contextMenuData = null
    this.contextMenuSeries = null
  }

  openContextMenu (data: RetentionGraphResponse['data'][number], series: DataSeries, x: number, y: number) {
    const menu = this.$refs.contextMenu as HTMLElement

    this.contextMenuData = data
    this.contextMenuSeries = series

    if (menu) {
      // const barLabel = data.labels[barIndex]
      // const barValue = data.datasets[0].data[barIndex]

      // Position and show context menu
      menu.style.left = `${x}px`
      menu.style.top = `${y}px`
      menu.style.display = 'block'
    }
  }

  contextMenuBreakdown () {
    const oldPeriod = this.period

    this.$nextTick(() => {
      const oldStart = this.start
      const oldEnd = this.end

      if (this.period === 'year') {
        this.period = 'month'
      } else if (this.period === 'month') {
        this.period = 'day'
      } else if (this.period === 'week') {
        this.period = 'day'
      }

      if (this.contextMenuData) {
        this.start = dateFromISO8601Date(this.contextMenuData.period_start)
        this.end = dateFromISO8601Date(this.contextMenuData.period_end)
      }

      this.closeContextMenu()

      this.getSeriesData()

      this.breakdownHistory.push({ period: oldPeriod, start: oldStart, end: oldEnd })
    })
  }

  contextMenuViewCancellations () {
    if (!this.contextMenuSeries || !this.contextMenuData) {
      return
    }

    this.retentionEventsList = {
      account_region: this.contextMenuSeries.accountRegion,
      countries: this.contextMenuSeries.countries,
      organisation_types: this.contextMenuSeries.orgTypes,
      products: this.contextMenuSeries.products,
      start: dateFromISO8601Date(this.contextMenuData.period_start),
      end: dateFromISO8601Date(this.contextMenuData.period_end),
      event: 'cancelled'
    }

    this.closeContextMenu()
  }

  contextMenuViewRetentions () {
    if (!this.contextMenuSeries || !this.contextMenuData) {
      return
    }

    this.retentionEventsList = {
      account_region: this.contextMenuSeries.accountRegion,
      countries: this.contextMenuSeries.countries,
      organisation_types: this.contextMenuSeries.orgTypes,
      products: this.contextMenuSeries.products,
      start: dateFromISO8601Date(this.contextMenuData.period_start),
      end: dateFromISO8601Date(this.contextMenuData.period_end),
      event: 'retained'
    }

    this.closeContextMenu()
  }

  popBreakdownHistory () {
    const state = this.breakdownHistory.pop()

    if (state) {
      this.period = state.period
      this.start = state.start
      this.end = state.end
    }

    this.getSeriesData()
  }

  formSeriesKey (series: DataSeries, period: 'year' | 'month' | 'day' | 'week', start: Date, end: Date) {
    return `${series.accountRegion}:${(series.countries?.length ?? 0) === 0 ? 'all' : series.countries?.join(',')}:${(series.orgTypes?.length ?? 0) === 0 ? 'all' : series.orgTypes?.join(',')}:${(series.products?.length ?? 0) === 0 ? 'all' : series.products?.join(',')}:${period}:${moment(start).format('YYYY-MM-DD')}:${moment(end).format('YYYY-MM-DD')}`
  }

  eventIgnored () {
    this.seriesMap.clear()

    this.getSeriesData()
  }

  async getSeriesData () {
    for (const series of this.activeSeries) {
      const seriesKey = this.formSeriesKey(series, this.period, this.start, this.end)

      if (!this.seriesMap.has(seriesKey)) {
        try {
          const data = await Api.getRetentionsGraph(this.stripUndefProps({
            products: series.products.length === 0 ? undefined : series.products,
            countries: series.countries.length === 0 ? undefined : series.countries,
            organisation_types: series.orgTypes.length === 0 ? undefined : series.orgTypes,
            period: this.period,
            start: this.start,
            end: this.end,
            account_region: series.accountRegion
          }))

          this.seriesMap.set(seriesKey, data)
          this.seriesTrigger++
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  lineColours = ['#a5d5d5', '#fde3ce', '#afacca', '#c1d4a9']

  get seriesData () {
    if (this.seriesTrigger === 0) {
      return {
        datasets: [],
        labels: []
      }
    }
    const graphData = {
      datasets: [] as any[],
      labels: [] as string[]
    }

    const labelMap: Map<string, 1> = new Map()
    let stackId = 0

    for (const series of this.activeSeries) {
      const seriesKey = this.formSeriesKey(series, this.period, this.start, this.end)
      const seriesCache = this.seriesMap.get(seriesKey)

      if (!seriesCache) {
        continue
      }

      graphData.datasets.push({
        type: 'line',
        backgroundColor: this.lineColours[stackId % this.lineColours.length],
        label:
`Retention Rate
${seriesCache.countries.length === 0 ? 'All Countries' : seriesCache.countries.join(', ')}
${seriesCache.products.length === 0 ? 'All Products' : seriesCache.products.join(', ')}
${seriesCache.organisation_types.length === 0 ? 'All Org Types' : seriesCache.organisation_types.join(', ')}`,
        yAxisID: 'B',
        data: this.retentionsByValue ? seriesCache.data.map((r, i) => r.value_due === 0 ? null : (r.value_renewed / r.value_due)) : seriesCache.data.map((r, i) => r.subscriptions_due === 0 ? null : (r.subscriptions_renewed / r.subscriptions_due)),
        fill: false,
        borderColor: this.lineColours[stackId % this.lineColours.length],
        segment: {
          borderDash: [10, 5]
        },
        pointRadius: 5,
        tension: 0,
        spanGaps: true,
        datalabels: {
          labels: {
            value: {
              align: 'top',
              color (ctx) {
                return 'black'
              },
              formatter (value, ctx) {
                return `${(value * 100).toFixed(0)}%`
              }
            }
          }
        }
      })

      const type = this.retentionsByValue
      const that = this

      graphData.datasets.push({
        label:
`Subscriptions Retained
${seriesCache.countries.length === 0 ? 'All Countries' : seriesCache.countries.join(', ')}
${seriesCache.products.length === 0 ? 'All Products' : seriesCache.products.join(', ')}
${seriesCache.organisation_types.length === 0 ? 'All Org Types' : seriesCache.organisation_types.join(', ')}`,
        yAxisID: 'A',
        backgroundColor: '#c2d5a8',
        data: seriesCache.data.map((r, i) => type ? r.value_renewed : r.subscriptions_renewed),
        stack: stackId,
        order: 2,
        datalabels: {
          labels: {
            value: {
              color (ctx) {
                return 'black'
              },
              display (ctx) {
                return that.activeSeries.length === 1
              },
              formatter (value, ctx) {
                return type ? that.formatCurrency(value, that.currency) : value
              }
            }
          }
        }
      })

      graphData.datasets.push({
        label:
`Subscriptions Lost
${seriesCache.countries.length === 0 ? 'All Countries' : seriesCache.countries.join(', ')}
${seriesCache.products.length === 0 ? 'All Products' : seriesCache.products.join(', ')}
${seriesCache.organisation_types.length === 0 ? 'All Org Types' : seriesCache.organisation_types.join(', ')}`,
        yAxisID: 'A',
        backgroundColor: '#e3a7c0',
        data: seriesCache.data.map((r, i) => this.retentionsByValue ? (r.value_due - r.value_renewed) : (r.subscriptions_due - r.subscriptions_renewed)),
        stack: stackId,
        order: 2,
        datalabels: {
          labels: {
            value: {
              color (ctx) {
                return 'black'
              },
              display (ctx) {
                return that.activeSeries.length === 1
              },
              formatter (value, ctx) {
                return type ? that.formatCurrency(value, that.currency) : value
              }
            }
          }
        }
      })

      seriesCache.data.forEach((r) => {
        labelMap.set(this.dateToLabel(r.period_start), 1)
      })

      stackId++
    }

    for (const key of labelMap.keys()) {
      graphData.labels.push(key)
    }

    return graphData
  }

  get yearOptions () {
    const options: { start: Date, end: Date, text: string }[] = []
    const date = moment('2019-01-01').utc().startOf('day')

    while (date.isSameOrBefore(moment().utc(), 'year')) {
      options.push({ start: date.toDate(), end: moment(date).utc().endOf('year').toDate(), text: date.format('YYYY') })
      date.add(1, 'year')
    }

    return options
  }

  get currency () {
    switch (this.accountRegion) {
      case 'GB':
        return 'gbp'
      case 'US':
        return 'usd'
      default:
        throw new UnreachableCaseError(this.accountRegion)
    }
  }

  dateToLabel (date: ISO8601Date | Date) {
    return this.period === 'day' ? moment(date).utc().format('DD/MM/YY')
      : this.period === 'week' ? `Week ${moment(date).utc().subtract(1, 'week').format('w')}`
        : this.period === 'month' ? moment(date).utc().format('MMM YYYY')
          : this.period === 'year' ? moment(date).utc().format('YY') : ''
  }

  addSeriesClicked () {
    this.isAddingNewSeries = true
  }

  closeAddDataSeries () {
    this.isAddingNewSeries = false
    this.resetAddSeries()
  }

  resetAddSeries () {
    this.products = []
    this.orgTypes = []
    this.countries = []
  }

  addDataSeries () {
    this.activeSeries.push({
      accountRegion: this.accountRegion,
      products: this.products,
      orgTypes: this.orgTypes,
      countries: this.countries
    })

    this.getSeriesData()
  }

  removeDataSeries (i: number) {
    console.log({ i })
    this.activeSeries.splice(i, 1)
    this.getSeriesData()
  }
}

