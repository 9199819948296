



























































import { Api, CountryCode, CountryInfo, GetRetentionEventsResponse, PagedResults, RetentionEventType, SchoolModel, SchoolOrgType, StripeInvoiceAccountRegion, Subscription, SubscriptionProductType, SuperUserSchoolSearchResult, TableState } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import SubscriptionCard from './views/components/SubscriptionCard.vue'

@Component({
  name: 'RetentionEventsList',
  components: {
    SubscriptionCard
  }
})
export default class RetentionEventsList extends Mixins(ComponentHelper) {
  @Prop({ required: true }) accountRegion!: StripeInvoiceAccountRegion

  @Prop({ default: () => [] }) countries!: CountryCode[]

  @Prop({ default: () => [] }) organisationTypes!: SchoolOrgType[]

  @Prop({ default: () => [] }) products!: SubscriptionProductType[]

  @Prop({ required: true }) start!: Date

  @Prop({ required: true }) end!: Date

  @Prop({ required: true }) event!: RetentionEventType

  subDetails: Subscription[] = []

  schoolDetails: SchoolModel[] = []

  countriesData: CountryInfo[] = []

  table: TableState = {
    page: 1,
    perPage: 10,
    term: '',
    sort: 'timestamp',
    dir: 'asc'
  }

  data: PagedResults<GetRetentionEventsResponse> = {
    items: [],
    total: 0
  }

  ignoreToggled (row: GetRetentionEventsResponse) {
    row.ignored = !row.ignored

    if (row.ignored) {
      this.$buefy.dialog.confirm({
        message: 'This event will be ignored in retention rate calculation',
        onCancel: () => {
          this.$nextTick(() => {
            row.ignored = false
          })
        },
        onConfirm: async () => {
          // api call
          await Api.updateRetentionEvent(row.id, { ignored: true })
          this.$emit('event-ignored')
        }
      })
    } else {
      this.$buefy.dialog.confirm({
        message: 'This event will be counted in retention rate calculation',
        onCancel: () => {
          this.$nextTick(() => {
            row.ignored = true
          })
        },
        onConfirm: async () => {
          // api call
          await Api.updateRetentionEvent(row.id, { ignored: false })
          this.$emit('event-ignored')
        }
      })
    }
  }

  async detailsOpened (row: GetRetentionEventsResponse) {
    try {
      const cachedSub = this.subDetails.find(s => s.id === row.subscription_id)

      if (!cachedSub && row.school_id) {
        const subs = await Api.superuserGetSchoolSubscriptions(row.school_id)

        const sub = subs.find(s => s.id === row.subscription_id)

        if (sub) {
          this.subDetails.push(sub)
        }
      }

      const cachedSchool = this.schoolDetails.find(s => s.id === row.school_id)

      if (!cachedSchool && row.school_id) {
        const school = await Api.getSchool(row.school_id)

        this.schoolDetails.push(school)
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load event details',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  mounted () {
    this.loadData()
    this.getCountries()
  }

  get currency () {
    return this.accountRegion === 'US' ? 'usd' : 'gbp'
  }

  pageChanged (page: number) {
    this.table.page = page
    this.loadData()
  }

  sortChanged (sort: string, dir: 'asc' | 'desc') {
    this.table.sort = sort
    this.table.dir = dir

    this.loadData()
  }

  async getCountries () {
    try {
      this.countriesData = await Api.getCountries()
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load retention events',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async loadData () {
    try {
      this.data = await Api.getRetentionEvents(this.stripUndefProps({
        account_region: this.accountRegion,
        countries: this.countries.length === 0 ? undefined : this.countries,
        end: this.end,
        event: this.event,
        organisation_types: this.organisationTypes.length === 0 ? undefined : this.organisationTypes,
        products: this.products.length === 0 ? undefined : this.products,
        start: this.start
      }), {
        dir: this.table.dir,
        skip: (this.table.page - 1) * this.table.perPage,
        sort: this.table.sort,
        take: this.table.perPage,
        term: this.table.term
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load retention events',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
